import React from "react";

import client1 from "../assets/images/client/1.png"
import client2 from "../assets/images/client/2.png"
import client3 from "../assets/images/client/3.png"
import client4 from "../assets/images/client/4.png"
import client5 from "../assets/images/client/5.png"


import TinySlider from "tiny-slider-react";
import '../../node_modules/tiny-slider/dist/tiny-slider.css';

const settings = {
    container: 'tiny-three-item',
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 12,
    responsive: {
        992: {
            items: 3
        },

        767: {
            items: 2
        },

        320: {
            items: 1
        },
    },
};



export default function Clients(){

    const clientsData = [
        {
            image:client1,
            name:"Divya Rajkumar",
            title:"CEO, Saish Enterprises",
            desc:"I am Divya Rajkumar from Saish Enterprises. Codefi Corporation has built and maintained our website and handled our technical needs for the past 2 years. Their dedication and expertise have significantly helped grow our business through social media and effective sales strategies. I highly recommend their team and I continue to work thier for my business growth."
        },
        {
            image:client2,
            name:"Prince Pandian",
            title:"CEO, MeItalian",
            desc:"I am Prince Pandian from Meitalian. He transformed our business website with a stunning, SEO-optimized website that has significantly boosted our online presence. Their team's expertise and dedication delivered exceptional results, bringing in more traffic and accelerating our business growth. We're thrilled with the outcome and highly recommend their services."
        },
        {
            image:client3,
            name:"Vikash Manoharan",
            title:"CEO, TourOn Holidays",
            desc:"Hi, I am Vikash Manoharan from TourOn, Coimbatore. We recently revamped our website with Codefi Corporation for enhanced user customization. Their team suggested great ideas and created user-friendly flows for booking tours, making it convenient for our users. I highly recommend them and look forward to working with them again in the future."
        },
        {
            image:client4,
            name:"Nandha Kumar",
            title:"Vidhaipom FOundation",
            desc:"Codefi Corporation did an outstanding job building our website for Vidhaipom Foundation. They designed the prototype and executed it flawlessly according to our requirements. Their attention to detail and professionalism stood out throughout the project. We are fully satisfied with their work and highly recommend their services for any web development needs."
        },
        {
            image:client5,
            name:"Madras Agricultural Journal",
            title:"Team",
            desc:"Codefi Corporation demonstrated exceptional dedication in resolving our website's major cybersecurity issues promptly and effectively. Their team not only rectified the errors but also met our requirements on time. We are confident in continuing our partnership with Codefi and highly recommend their services to elevate your digital business."
        },
        // {
        //     image:client6,
        //     name:"FEMI9 STOCKIST",
        //     title:"Team",
        //     desc:""
        // },
    ]
    return(
        <div className="row">
            <div className="col-12 mt-4">
                <div className="tiny-three-item">
                    <TinySlider settings={settings}>
                        {clientsData.map((item,index)=>{
                            return(
                            <div className="tiny-slider client-testi" key={index} >
                                <div className="card border-0 bg-white">
                                    <div className="card-body p-4 rounded shadow m-2">
                                        <i className="mdi mdi-format-quote-open fs-1 text-primary"></i>

                                        <p className="text-muted text-center fst-italic mb-0">{item.desc}</p>
                                    
                                        <div className="d-flex mt-4">
                                            <img src={item.image} className="avatar avatar-md-sm rounded-circle shadow-md" alt=""/>
                                            <div className="flex-1 ms-3">
                                                <h6 className="mb-0">{item.name}</h6>
                                                <small className="text-muted">{item.title}</small>
                                            </div>
                                        </div>

                                        <ul className="list-unstyled mb-0 mt-3 text-warning h5">
                                            <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                            <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                            <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                            <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                            <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </TinySlider>
                </div>
            </div>
        </div>
    )
}