import React from 'react';
import Whatsapp from '../assets/images/whatsapp-icon.png';

const WhatsAppButton = () => {
  const handleClick = () => {
    const phoneNumber = '+918148831239';
    const message = 'Hello, which time is best for the discuss?';
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div className="whatsapp-button-container">
      <button className="whatsapp-button" onClick={handleClick}>
        <img src={Whatsapp} alt="WhatsApp" className="whatsapp-icon" />
      </button>
    </div>
  );
};

export default WhatsAppButton;
