import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const SHEET_URI = "https://sheet.best/api/sheets/60e6b495-a750-451c-a9e1-be52f7689b36";

export default function Popup({ open, setOpen, setStep }) {
    const initialFormState = {
        name: "",
        email: "",
        phone: "",
        comments: "",
    };

    const [form, setForm] = useState(initialFormState);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!form.name || !form.email || !form.phone || !form.comments) {
            toast.error("Please fill in all fields.");
            return;
        }

        const userData = {
            Name: form.name,
            Email: form.email,
            Mobile: form.phone,
            Comments: form.comments,
            Timestamp: new Date().toISOString(),
        };

        axios
            .post(SHEET_URI, userData)
            .then((res) => {
                console.log(res);
                toast.success("Submitted successfully");
                setOpen(false);
                setForm(initialFormState); // Resetting form fields to initial state
                setStep(0);
            })
            .catch((err) => {
                console.error(err);
                toast.error("Something went wrong!");
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    return (
        <div>
            <div
                className={`${open ? "flex" : "hidden"
                    } justify-center items-center duration-500 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-[#00000066] h-full w-full`}
            >
                <div className="flex flex-col fixed z-50 justify-center gap-3 bg-white px-12 pt-12 pb-11 rounded-lg">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Your Name <span className="text-danger">*</span></label>
                                    <input name="name" id="name" value={form.name}
                                        onChange={handleInputChange}
                                        required type="text" className="form-control" placeholder="Name :" />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                    <input name="email" id="email" value={form.email}
                                        onChange={handleInputChange}
                                        type="email" className="form-control" placeholder="Email :" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Your Mobile Number <span className="text-danger">*</span></label>
                                    <input type="tel" className="form-control"
                                        placeholder="Mobile No"
                                        name="phone"
                                        value={form.phone}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Comments <span className="text-danger">*</span></label>
                                    <textarea name="comments" id="comments" rows="4" value={form.comments}
                                        onChange={handleInputChange} className="form-control" placeholder="Message :"></textarea>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="d-grid">
                                    <button type="submit" id="submit" name="send" disabled={!form.name || !form.email || !form.phone || !form.comments} className="btn btn-primary">Send Message</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
