import React from "react";
import { Link } from "react-router-dom";

import logoDark from "../../assets/images/codefi.png"

import {AiFillLinkedin,FaFacebookF,AiOutlineInstagram,FiMail} from "../../assets/icons/vander"

export default function SmallFooter(){
    return(
        <>
        <footer className="bg-footer footer-bar">
            <div className="footer-py-30">
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col-sm-3">
                            <div className="text-sm-start">
                                <Link to="#" className="logo-footer w-[150px]">
                                    <img src={logoDark} alt=""/>
                                </Link>
                            </div>
                        </div>

                        <div className="col-sm-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="text-center">
                                <p className="mb-0">© {new Date().getFullYear()} Codefi Corporation | All rights reserverd <Link to="https://codeficorp.com/" target="_blank" className="text-reset"></Link>.</p>
                            </div>
                        </div>
    
                        <div className="col-sm-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <ul className="list-unstyled social-icon foot-social-icon text-sm-start mb-0">
                                <li className="list-inline-item ms-1"><Link to="https://www.linkedin.com/in/soundar-rajan-73a4771aa/" target="_blank" className="rounded"><AiFillLinkedin/></Link></li>
                                <li className="list-inline-item ms-1"><Link to="https://www.facebook.com/profile.php?id=61561625797318" target="_blank" className="rounded"><FaFacebookF/></Link></li>
                                <li className="list-inline-item ms-1"><Link to="https://www.instagram.com/codefi_vault/" target="_blank" className="rounded"><AiOutlineInstagram/></Link></li>
                                <li className="list-inline-item ms-1"><Link to="mailto:codeficorporation@gmail.com" className="rounded"><FiMail/></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </>
    )
}