import React from "react";
import { Link } from "react-router-dom";
import {FiPhoneCall, FiMail,FiMapPin} from '../assets/icons/vander'
import Form from './Form'
export default function GetInTuchFour(){
    return(
        <section className="section" id="contact">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="section-title mb-4">
                        <h4 className="title mb-4">Contact Us</h4>
                        <p className="text-muted para-desc mb-0">Start working with Fronter that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>

                    <div className="row">
                        <div className="col-12 mt-4">
                            <div className="d-flex features feature-primary">
                                <div className="feature-icon rounded  text-center">
                                    <FiPhoneCall className="d-block h3 mb-0 icon"/>
                                </div>
                                <div className="flex-1 ms-4">
                                    <h6 className="mb-1">Phone</h6>
                                    <Link to="tel:+918148831239" className="link">+91 81488 31239</Link>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-12 mt-4">
                            <div className="d-flex features feature-primary">
                                <div className="feature-icon rounded text-center">
                                    <FiMail className="d-block mb-0 icon"/>
                                </div>
                                <div className="flex-1 ms-4">
                                    <h6 className="mb-1">Email</h6>
                                    <Link to="mailto:codeficorporation@gmail.com" className="link">codeficorporation@gmail.com</Link>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-12 mt-4">
                            <div className="d-flex features feature-primary">
                                <div className="feature-icon rounded  text-center">
                                    <FiMapPin className="d-block h3 mb-0 icon"/>
                                </div>
                                <div className="flex-1 ms-4">
                                    <h6 className="mb-1">Location</h6>
                                    <Link to="" className="lightbox text-primary" data-type="iframe" data-group="iframe" data-width="1024px" data-height="576px">View on Google map</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-8 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div className="p-4 rounded shadow">
                    <Form/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}