import React from 'react';
import { Route, Routes } from 'react-router-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./assets/scss/style.scss"
import "./assets/css/materialdesignicons.min.css"
import IndexStartup from './pages/index-startup';

function App() {
  return (
    <>
    <Routes>
      <Route path='/' element={<IndexStartup/>}/>     
    </Routes>
    </>
  );
}

export default App;
