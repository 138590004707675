import brand1 from "../assets/images/brands/1.png"
import brand2 from "../assets/images/brands/2.png"
import brand3 from "../assets/images/brands/3.png"
import brand4 from "../assets/images/brands/4.png"
import brand5 from "../assets/images/brands/5.png"
import brand6 from "../assets/images/brands/6.jpg"


export const partnersImg = [brand1,brand2,brand3,brand4,brand5,brand6]

