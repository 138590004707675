import React from "react";
import { Link } from "react-router-dom";

import logo36 from "../assets/images/logo-icon-36.png"
import squareDanger from "../assets/images/square/square-danger.png"
import squareSuccess from "../assets/images/square/square-success.png"
import squarePrimary from "../assets/images/square/square-primary.png";
import bgImg from "../assets/images/startup/01.png"
import BgImg02 from "../assets/images/digital/bg01.jpg"
import about01 from "../assets/images/business/about01.jpg"
import about02 from "../assets/images/business/about02.jpg"
import team1 from "../assets/images/client/111.jpg"
import team2 from "../assets/images/client/222.jpg"
import StartupNavbar from "../componants/navbar/startupNavbar";
import Clients from "../componants/clients";
import GetInTuchFour from "../componants/getInTuchFour";
import SmallFooter from "../componants/footer/smallFooter";

import { Parallax } from 'react-parallax';
import { partnersImg } from "../data/data";

import { HiOutlinePresentationChartLine, BiWater, TfiDropbox, MdOutlineCenterFocusWeak, MdArrowForward, FiInstagram, } from "../assets/icons/vander"

import "../../node_modules/react-modal-video/scss/modal-video.scss"
import ScrollTop from "../componants/scrollTop";
import WhatsApp from "../componants/Whatsapp";

export default function IndexStartup() {

    const featuresData = [
        {
            id: 1,
            icon: HiOutlinePresentationChartLine,
            title: "Website Development",
            desc: " Our team excels in creating stunning, high-performance websites using MERN Stack, PHP, and WordPress, providing end-to-end solutions for new builds or revamps.",
        },
        {
            id: 2,
            icon: TfiDropbox,
            title: "Web Security",
            desc: "We prioritize website security with robust cybersecurity services, shielding your site from hackers and spammers using cutting-edge technologies and practices.",
        },
        {
            id: 3,
            icon: FiInstagram,
            title: "Social Media Management",
            desc: "Maximize your online impact with our social media management services, covering Instagram to Google Business for enhanced brand visibility and engagement.",
        },
    ]

    // const aboutData = [
    //     {
    //         icon: BiWater,
    //         title: 'On Time Delivery',
    //         desc: 'We deliver in the on-time for your requirement.'
    //     },
    //     {
    //         icon: TfiDropbox,
    //         title: 'Secure Environment',
    //         desc: 'We provide securable enviroment for your website.'
    //     },
    //     {
    //         icon: MdOutlineCenterFocusWeak,
    //         title: 'Team Work',
    //         desc: 'Our team is dedicated to work for your business to give the presence in digital.'
    //     }
    // ]

    const scrollToContact = () => {
        document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    };

    const teamData = [
        {
            image: team1,
            name: "Soundar Rajan",
            title: "Web Devloper"
        },
        {
            image: team2,
            name: "Richard Singh",
            title: "Pentrating Tester"
        },
    ]

    return (
        <>
            <StartupNavbar />
            <section className="bg-home startup-wrapper d-flex align-items-center overflow-hidden" id="home">
                <div className="bg-overlay bg-transparent" id="overlay">
                    <img src={bgImg} className="bg-image" alt="Background" />
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-12">
                                <div className="title-heading">
                                    <img src={logo36} className="hero-img" alt="" /> <br />
                                    <span className="hero-h">Build, Grow, And <br /> Manage Your Brand</span>
                                    <p className="text-muted para-descri mb-0">Start working with Codefi that we can provide everything you need to present in the digital.</p>

                                    <div className="mt-4">
                                        <button onClick={scrollToContact} className="btn hire btn-primary">
                                            Hire Us
                                        </button>
                                    </div>
                                </div>

                                <img src={squareDanger} className="img-fluid rounded-pill bg-image-position avatar avatar-md-md mover-2 z-index-0" alt="" />
                                <img src={squareSuccess} className="img-fluid rounded-md avatar avatar-md-md bg-image-position-2 spin-anything z-index-0" alt="" />
                            </div>
                        </div>
                        <img src={squarePrimary} className="img-fluid rounded-pill position-absolute top-0 start-50 translate-middle-x avatar avatar-md-md zoom-in-out z-index-0" alt="" />
                    </div>
                </div>
            </section>

            <section className="section" id="about">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="about-image position-relative">
                                <img src={about01} className="img-fluid rounded shadow" alt="" />

                                <div className="about-image-position">
                                    <img src={about02} className="img-fluid rounded shadow-lg" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className="section-title ms-lg-5">
                                <h6 className="text-primary fw-normal">About Us</h6>
                                <h4 className="title mb-4">Why we suitable for you?</h4>
                                <p className="text-muted">our team of skilled professionals brings extensive experience in web development, cybersecurity, and social media management. We recognize that every business is unique, which is why we tailor our services to meet your specific needs.</p>
                                <p className="text-muted mb-0">Your website's security is our top priority, and we implement robust measures to protect it against threats. Additionally, our social media strategies are crafted to enhance your online presence and drive meaningful engagement.</p>

                                <div className="mt-4 pt-2">
                                    <button onClick={scrollToContact} className="btn btn-primary">
                                        Hire Us <MdArrowForward />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section  py-0 mt-5 mt-100" id="cta">
                <Parallax
                    blur={{ min: 0, max: 0 }}
                    bgImage={BgImg02}
                    bgImageAlt="the dog"
                    strength={500}
                    bgportfolioImageize="100%"
                    bgStyle={{ with: "auto", height: "100%" }}
                    style={{ position: "absolute", width: "100%", height: "100%" }}
                >
                </Parallax>
                <div className="bg-overlay"></div>
                <div className="container section">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="section-title">
                                <h4 className="title title-dark text-white mb-4">Stop leaving money on the table.</h4>
                                <p className="text-light para-dark para-desc mx-auto">Start working with Codefi that we can provide everything you need to present in the digital.</p>
                                {/* <Link to="#contact" className="sub-menu-item play-btn border border-light mt-4 lightbox">
                                <FiPlay className="fea icon-ex-md text-white title-dark"/>
                            </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section bg-soft-primary" id="features">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title text-dark mb-3">Our Services</h4>
                                <p className="text-muted para-dark para-desc mx-auto">we specialize in empowering startups and new entrepreneurs by offering customized solutions tailored to your unique business needs.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {featuresData.map((item, index) => {
                            const Icon = item.icon
                            return (
                                <div className="col-lg-4 col-sm-6 mt-4 pt-2" key={index}>
                                    <div className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
                                        <div className="icons text-center rounded">
                                            <Icon className="icon mb-0" />
                                        </div>
                                        <div className="content mt-4">
                                            <Link to={`/page-service-detail/${item.id}`} className="h5 text-dark title">{item.title}</Link>
                                            <p className="text-muted mt-3">{item.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <section>
                <div className="container mt-100 mt-60" id="teams">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="section-title text-center mb-4 pb-2">
                                <h6 className="text-primary fw-normal mb-2">Our Minds</h6>
                                <h4 className="title mb-4">Management Team</h4>
                                <p className="text-muted para-desc mb-0 mx-auto">Start working with Fronter that can provide everything you need to generate awareness, drive traffic, connect.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {teamData.map((item, index) => {
                            return (
                                <div className="col-lg-3 col-md-4 col-12 mt-4 pt-2 " key={index}>
                                    <div className="card team team-primary text-center">
                                        <div className="card-img team-image d-inline-block mx-auto rounded-pill avatar avatar-ex-large overflow-hidden">
                                            <img src={item.image} className="img-fluid" alt="" />
                                        </div>

                                        <div className="content mt-3">
                                            <Link to="/page-team-detail" className="text-dark h5 mb-0 title">{item.name}</Link>
                                            <h6 className="text-muted mb-0 fw-normal">{item.title}</h6>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className="section" id="testimonial">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="text-primary fw-normal mb-2">Testimonial</h4>
                                <h4 className="title mb-4">Hear from Our Satisfied Clients</h4>
                                <p className="text-muted para-desc mb-0 mx-auto">Discover what our clients have to say about their experience partnering with Codefi Corporation. Their success stories highlight our commitment to delivering exceptional digital solutions tailored to their needs.</p>
                            </div>
                        </div>
                    </div>

                    <Clients />
                </div>

                <div className="container mt-5" id="brand">
                    <div className="row justify-content-center">
                        {partnersImg.map((item, index) => {
                            return (
                                <div className="col-lg-2 col-md-2 col-6 py-2" key={index}>
                                    <div className="text-center">
                                        <img src={item} className="avatar avatar-md-md w-auto" alt="" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <div id="contact">
                <GetInTuchFour />
            </div>


            <SmallFooter />
            <ScrollTop />
            <WhatsApp/>

        </>)
}